<template>
  <div>
    new page
  </div>
</template>
<script>
export default {
  name: 'WeeklyReportDetail',
  data: function () {
    return {
            
    }
  }
}
</script>